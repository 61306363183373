/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useState, useEffect, useRef, memo, FormEvent } from 'react'
import SendIcon from '@mui/icons-material/Send'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ToolTipWithAnIcon from './ToolTipWithAnIcon'

type Props = {
	type?: string
	name: string
	value: string
	placeholder?: string
	onChange?: any
	id?: any
	focus?: any
	autoFocus?: any
	autoComplete?: any
	onFocus?: any
	onBlur?: any
	disabled?: any
	inputProps?: any
	className?: any
	onKeyDown?: any
	onFocusChange?: any
	containerClassName?: any
	labelClassname?: any
	hasError?: any
	alwaysShowError?: any
	submitted?: any
	error?: any
	noBorder?: any
	maxLength?: any
	min?: any
	max?: any
	ref?: any
	label?: any
	step?: any
	hasSearchIcon?: boolean
	sendButton?: JSX.Element
	validated?: boolean
	validationText?: string
	unit?: string
	handleSend?: any
	tooltip?: any
	textarea?: boolean
	handleSearch?: () => void
	noShowError?: boolean
}

// eslint-disable-next-line react/display-name
const Input = memo(
	({
		type = 'text',
		name,
		value,
		placeholder,
		onChange,
		id,
		focus = false,
		autoFocus = false,
		autoComplete,
		onFocus,
		onBlur,
		disabled,
		inputProps,
		className,
		onKeyDown,
		onFocusChange,
		containerClassName,
		labelClassname,
		hasError,
		alwaysShowError,
		submitted,
		error,
		noBorder,
		min,
		max,
		maxLength,
		ref,
		label,
		step,
		hasSearchIcon,
		handleSearch,
		sendButton,
		validated,
		validationText,
		unit,
		handleSend,
		tooltip,
		textarea,
		noShowError,
		...props
	}: Props): JSX.Element => {
		const [isFocus, setIsFocus] = useState(false)
		const [isDirty, setIsDirty] = useState(false)
		const inputRef = useRef(ref || null)

		useEffect(() => {
			// If user already click the input and blur it => call the onFocusChange
			if (isDirty && typeof onFocusChange === 'function') {
				onFocusChange(isFocus, name)
			}
		}, [isDirty, isFocus, name, onFocusChange])
		useEffect(() => {
			// Set focus to true to make input focus
			if (focus) {
				inputRef.current.focus()
				inputRef.current.select()
			}
		}, [focus])

		const _onFocus = () => {
			setIsDirty(true)
			setIsFocus(true)
			if (typeof onFocus === 'function') {
				return onFocus()
			}
		}

		const _onBlur = () => {
			setIsFocus(false)
			if (typeof onBlur === 'function') {
				return onBlur()
			}
		}

		const _onKeyDown = (e: { key: string }) => {
			// Handle enter
			if (e.key === 'Enter') {
				if (onKeyDown) {
					return onKeyDown(e)
				} else if (typeof onFocusChange === 'function') {
					return onFocusChange(false, name)
				}
			}
		}

		// Input class name
		const inputClassName = className ? className.split(' ') : []
		if (noBorder) {
			inputClassName.push(
				`input__no-border ${
					(alwaysShowError || ((isDirty || submitted) && !isFocus)) && hasError
						? 'has-error'
						: ''
				}`,
			)
			// inputClassName.push('input__no-border')
		} else {
			inputClassName.push(
				`input__border ${
					(alwaysShowError || ((isDirty || submitted) && !isFocus)) && hasError
						? 'has-error'
						: ''
				}`,
			)
			// inputClassName.push('input__border')
		}
		const handleOnChange = (e: FormEvent<HTMLInputElement>) => {
			if (
				!(e.currentTarget.value.length === 1 && e.currentTarget.value === ' ')
			) {
				onChange(e)
			}
		}
		const inputContainerClassName = containerClassName
			? containerClassName.split(' ')
			: []
		inputContainerClassName.push('input__container')
		const setLabelClassName: any = labelClassname
			? [`input__container ${labelClassname}`]
			: ['input__container']
		// Show error when input is touched or form is submitted
		return (
			<div className={inputContainerClassName}>
				{!label ? null : !tooltip ? (
					<p className={setLabelClassName}>{label}</p>
				) : (
					<div className='input-label-container'>
						<p className={setLabelClassName}>{label}</p>
						<ToolTipWithAnIcon title={tooltip} />
					</div>
				)}
				<div className='input__row'>
					{textarea ? (
						<textarea
							type={type}
							name={name}
							value={value}
							placeholder={placeholder}
							onChange={handleOnChange}
							ref={inputRef}
							id={id}
							disabled={disabled}
							onKeyDown={_onKeyDown}
							autoFocus={autoFocus}
							maxLength={maxLength}
							autoComplete={autoComplete}
							{...inputProps}
							step={step}
							min={min}
							max={max}
							onFocus={_onFocus}
							onBlur={_onBlur}
							className={inputClassName.join(' ')}
						/>
					) : (
						<input
							type={type}
							name={name}
							value={value}
							placeholder={placeholder}
							onChange={handleOnChange}
							ref={inputRef}
							id={id}
							disabled={disabled}
							onKeyDown={_onKeyDown}
							autoFocus={autoFocus}
							maxLength={maxLength}
							autoComplete={autoComplete}
							{...inputProps}
							step={step}
							min={min}
							onFocus={_onFocus}
							onBlur={_onBlur}
							className={inputClassName.join(' ')}
						/>
					)}

					{hasSearchIcon && (
						<div className='input__row--search-icon'>
							<SearchRounded
								onClick={handleSearch ? handleSearch : () => {}}
								fontSize='large'
								color='disabled'
							/>
						</div>
					)}
					{sendButton && handleSend && (
						<div className='input__row--send-button '>
							<IconButton
								onClick={handleSend}
								disabled={value === '' || disabled}
							>
								{sendButton}
							</IconButton>
						</div>
					)}
					{unit && (
						<div className='input__row--unit'>
							<div>{unit}</div>
						</div>
					)}
				</div>
				{(alwaysShowError || ((isDirty || submitted) && !isFocus)) &&
				hasError ? (
					<p className='input__error'>{error}</p>
				) : !noShowError ? (
					<p className='input__error'>{''}</p>
				) : null}
				{validated && <p className='input__valid'>{validationText}</p>}
			</div>
		)
	},
)

export default Input
