/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import {
	ProjectsActions,
	getAllProjects,
	getProjectById,
} from '../actions/project'
import {
	SIGN_OUT,
	SIGN_UP_SUCCESS,
	UNSUBSCRIBE_DATA_SOURCE_SUCCESS,
	UPDATE_SUBSCRIBER_PROJECT_SUCCESS,
	POST_GOOGLE_ANALYTIC_VIEWS_SUCCESS,
	POST_GOOGLE_ADS_VIEWS_SUCCESS,
	POST_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS,
	CREATE_PROJECT_SUCCESS,
	MODIFY_PROJECT_SUCCESS,
	CHECK_GENERATE_STATUS_SUCCESS,
	POST_FACEBOOK_AD_MANAGERS_SUCCESS,
	POST_FACEBOOK_PAGE_SITES_SUCCESS,
	RESET_DRAFT_PROJECT,
	CHECK_PROJECT_AMOUNT_SUCCESS,
	UPDATE_PROJECT_TYPE_SUCCESS,
	EDIT_DRAFT_PROJECT,
	EDIT_PROJECT_FIELD,
	GET_ALL_PROJECTS_SUCCESS,
	CREATE_BULK_PROJECTS_SUCCESS,
	GET_PROJECT_BY_ID_SUCCESS,
	RESET_PROJECT_STATE,
	POST_GENERATE_SINGLE_SUGGESTION_SUCCESS,
	POST_GOOGLE_ANALYTIC_GA4_PROPERTY_SUCCESS,
	UPDATE_DATA_SOURCE_SUCCESS,
	CLEAR_EXTERNAL_LINKS,
	POST_EXTERNAL_LINKS_FOR_SUGGESTION_SUCCESS,
	POST_GOOGLE_ANALYTICS_GOALS,
	POST_GOOGLE_ANALYTICS_GOALS_SUCCESS,
	MODIFY_EDIT_PROJECT_FIELD,
	GET_CONTRIBUTOR_LIST_SUCCESS,
	UPDATE_CONTRIBUTOR_DATA_SUCCESS,
	DELETE_CONTRIBUTOR_DATA_SUCCESS,
	INVITE_CONTRIBUTOR_TO_PROJECT_SUCCESS,
	POST_LINKEDIN_PAGES_SUCCESS,
	GET_PRODUCT_REFERENCE_SUCCESS,
	SAVE_PRODUCT_REFERENCE_SUCCESS,
	RESET_EDIT_PROJECT_STATE,
	DELETE_PRODUCT_REFERENCE_SUCCESS,
} from '../actions/types'
import {
	IDataSource,
	ESubscriptionLevel,
	IExternalLinksForSuggestion,
	IProject,
	SingleSuggestionResponse,
} from '../constants'
import { getActiveDataSource } from '../helpers/fnHelpers'
import { patchContributors } from '../helpers/dataTransformer'
import { EUserRoles } from './user'

export type ProjectContributor = {
	id: number
	accessLevel: EUserRoles
	firstName: string
	lastName: string
	email: string
}

export type ProjectState = {
	project: IProject
	contributorList: ProjectContributor[]
	editProject: IProject
	draftProject: {
		name: string | null
		url: string | null
		isEcommerce: boolean
		conversionRate: string | null
		conversionValue: string | null
		languageName: string | null
		locationCode: number | null
		accessToDataSources: boolean
		term: string
		termContent: string
	}
	checkProjectAmount: {
		max: number
		numberOfActiveProject: number
		numberOfProjectCreated: number
	}
	projects: Pick<IProject, 'id' | 'name'>[]
	generatedSuggestion: SingleSuggestionResponse
	externalLinks: IExternalLinksForSuggestion[]
	productReference: any[]
}

export const projectInitialState: ProjectState = {
	project: {
		id: -1,
		name: null,
		// emailSubscribers: null,
		phoneSubscribers: null,
		timePerWeek: null,
		isActivated: null,
		languageName: 'English',
		locationCode: 2826,
		projectUrl: null,
		isEcommerce: false,
		conversionValue: null,
		conversionRate: null,
		dataSources: [],
		generateSuggestionStatus: [],
		// templateId: null,
		shopifyProductFeedInterval: 0,
		company: {
			id: -1,
			name: '',
			subscriptionLevel: ESubscriptionLevel.NOT_SUBSCRIBED,
		},
	},
	draftProject: {
		name: '',
		url: '',
		isEcommerce: false,
		conversionRate: '1',
		conversionValue: '200',
		languageName: 'English',
		locationCode: 2826,
		accessToDataSources: true,
		term: '',
		termContent: '',
	},
	editProject: {
		id: -1,
		name: null,
		// emailSubscribers: null,
		phoneSubscribers: null,
		timePerWeek: null,
		isActivated: null,
		languageName: 'English',
		locationCode: 2826,
		projectUrl: null,
		isEcommerce: false,
		conversionValue: null,
		conversionRate: null,
		dataSources: [],
		generateSuggestionStatus: [],
		// templateId: null,
		shopifyProductFeedInterval: 0,
	},
	checkProjectAmount: {
		max: 0,
		numberOfActiveProject: 0,
		numberOfProjectCreated: 0,
	},

	projects: [],
	generatedSuggestion: { keywordData: null, suggestion: null, description: [] },
	externalLinks: [],
	contributorList: [],
	productReference: [],
}

export const projectReducer = (
	state = projectInitialState,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	action: ProjectsActions,
): ProjectState => {
	switch (action.type) {
		case SIGN_UP_SUCCESS:
		case SIGN_OUT:
			return projectInitialState
		case RESET_DRAFT_PROJECT:
			return { ...state, draftProject: projectInitialState.draftProject }
		case RESET_PROJECT_STATE:
			return {
				...state,
				draftProject: projectInitialState.draftProject,
				project: projectInitialState.project,
			}
		case RESET_EDIT_PROJECT_STATE:
			return {
				...state,
				editProject: state.project,
			}
		case CHECK_PROJECT_AMOUNT_SUCCESS:
			return {
				...state,
				checkProjectAmount: {
					...state.checkProjectAmount,
					...action.payload.data,
				},
			}
		case CREATE_BULK_PROJECTS_SUCCESS: {
			return {
				...state,
				projects: [...state.projects, ...action.payload.data],
			}
		}
		case CREATE_PROJECT_SUCCESS: {
			const newProject = {
				...projectInitialState.project,
				...action.payload.data,
			}
			return {
				...state,
				project: newProject,
				projects: [...state.projects, newProject],
				editProject: newProject,
			}
		}

		case EDIT_DRAFT_PROJECT: {
			return {
				...state,
				draftProject: {
					...state.draftProject,
					...action.payload,
				},
			}
		}
		case EDIT_PROJECT_FIELD: {
			return {
				...state,
				project: {
					...state.project,
					...action.payload,
				},
			}
		}
		case MODIFY_EDIT_PROJECT_FIELD: {
			if (!action.payload.field) {
				return {
					...state,
					editProject: {
						...state.editProject,
						...action.payload,
					},
				}
			} else {
				return {
					...state,
					editProject: {
						...state.editProject,
						[action.payload.field]: action.payload.value,
					},
				}
			}
		}

		case CHECK_GENERATE_STATUS_SUCCESS: {
			return {
				...state,
				project: {
					...state.project,
					generateSuggestionStatus: action.payload.data,
				},
			}
		}
		case UPDATE_PROJECT_TYPE_SUCCESS:
		case MODIFY_PROJECT_SUCCESS: {
			if (action.payload.data.isActivated) {
				return {
					...state,
					project: { ...state.project, ...action.payload.data },
					editProject: { ...state.editProject, ...action.payload.data },
				}
			} else {
				const newAllProjectsList = state.projects.filter(
					project => project.id !== action.payload.data.id,
				)
				return {
					...state,
					projects: [...newAllProjectsList],
				}
			}
		}
		case UNSUBSCRIBE_DATA_SOURCE_SUCCESS:
		case POST_GOOGLE_ANALYTIC_VIEWS_SUCCESS:
		case POST_GOOGLE_ADS_VIEWS_SUCCESS:
		case POST_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS:
		case POST_FACEBOOK_AD_MANAGERS_SUCCESS:
		case UPDATE_SUBSCRIBER_PROJECT_SUCCESS:
		case POST_GOOGLE_ANALYTIC_GA4_PROPERTY_SUCCESS:
		case POST_GOOGLE_ANALYTICS_GOALS_SUCCESS:
		case POST_FACEBOOK_PAGE_SITES_SUCCESS:
		case POST_LINKEDIN_PAGES_SUCCESS:
			return { ...state, project: { ...state.project, ...action.payload.data } }
		case UPDATE_DATA_SOURCE_SUCCESS: {
			const updatedDataSourceId = action.payload.data.id
			const foundDataSourceIndex = state.project.dataSources.findIndex(
				dataSource => dataSource.id === updatedDataSourceId,
			)
			const clonedProject = { ...state.project }
			clonedProject.dataSources[foundDataSourceIndex] = Object.assign(
				clonedProject.dataSources[foundDataSourceIndex],
				action.payload.data,
			)
			return { ...state, project: clonedProject }
		}
		case POST_EXTERNAL_LINKS_FOR_SUGGESTION_SUCCESS: {
			return { ...state, externalLinks: action.payload.data }
		}
		case GET_PROJECT_BY_ID_SUCCESS:
			return {
				...state,
				project: action.payload.data,
				editProject: action.payload.data,
			}
		case INVITE_CONTRIBUTOR_TO_PROJECT_SUCCESS:
			return {
				...state,
				contributorList: action.payload.data,
			}
		case DELETE_CONTRIBUTOR_DATA_SUCCESS:
		case GET_CONTRIBUTOR_LIST_SUCCESS:
			return {
				...state,
				contributorList: action.payload.data,
			}
		case GET_PRODUCT_REFERENCE_SUCCESS:
		case SAVE_PRODUCT_REFERENCE_SUCCESS:
		case DELETE_PRODUCT_REFERENCE_SUCCESS:
			return {
				...state,
				productReference: action.payload.data,
			}
		case UPDATE_CONTRIBUTOR_DATA_SUCCESS: {
			const patchedContributors = patchContributors(
				state.contributorList,
				action.payload.data,
			)
			return {
				...state,
				contributorList: patchedContributors,
			}
		}
		case GET_ALL_PROJECTS_SUCCESS:
			return { ...state, projects: action.payload.data }
		case POST_GENERATE_SINGLE_SUGGESTION_SUCCESS:
			return { ...state, generatedSuggestion: action.payload.data }
		case CLEAR_EXTERNAL_LINKS:
			return { ...state, externalLinks: [] }
		default:
			return state
	}
}

export const projectSelectors = {
	getProject: (state = projectInitialState): ProjectState['project'] =>
		state.project,
	getActiveDataSource: (state = projectInitialState): IDataSource =>
		state.project.dataSources && state.project.dataSources.length
			? getActiveDataSource(state.project.dataSources)
			: {},
	getContributorList: (
		state = projectInitialState,
	): ProjectState['contributorList'] => state.contributorList,
	getProductReferenceList: (
		state = projectInitialState,
	): ProjectState['productReference'] => state.productReference,
	getEditProject: (state = projectInitialState): ProjectState['project'] =>
		state.editProject,
	getAllProjects: (state = projectInitialState): ProjectState['projects'] =>
		state.projects,
	getDraftProject: (
		state = projectInitialState,
	): ProjectState['draftProject'] => state.draftProject,
	getCheckProjecAmount: (
		state = projectInitialState,
	): ProjectState['checkProjectAmount'] => state.checkProjectAmount,
	getGeneratedSuggestion: (
		state = projectInitialState,
	): ProjectState['generatedSuggestion'] => state.generatedSuggestion,
	getExternalLinks: (
		state = projectInitialState,
	): ProjectState['externalLinks'] => state.externalLinks,
}
