import {
	API,
	IReportTemplateItem,
	ReportTemplatesType,
	ReportTemplateItemType,
	IReportPage,
	ReportTemplateCustom,
	IReportComponent,
	EMarketingReportTimeFrame,
} from './../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import { ActionWithoutPayload } from './audit'
import {
	CHANGE_DRAFT_REPORT_TEMPLATE_FIELD,
	CHANGE_DRAFT_REPORT_PAGE_FIELD,
	CLEAR_DRAFT_PROJECT_TEMPLATE,
	POST_REPORT_TEMPLATE,
	GET_REPORT_TEMPLATES,
	SELECT_REPORT_TEMPLATE,
	DELETE_REPORT_TEMPLATE,
	UPDATE_REPORT_TEMPLATE,
	POST_UPDATE_REPORT_TEMPLATE_SUCCESS,
	POST_REPORT_TEMPLATE_SUCCESS,
	GET_REPORT_TEMPLATES_SUCCESS,
	GET_REPORT_DEFAULT_TEMPLATES_SUCCESS,
	POST_UPDATE_REPORT_TEMPLATE,
	DELETE_REPORT_TEMPLATE_SUCCESS,
	GET_REPORT_DEFAULT_TEMPLATES,
	GET_AUTOMATE_REPORT_DATA,
	GET_GENERATED_MONTHLY_REPORT,
	GET_SAVED_REPORT_FILE,
	SAVE_CUSTOM_REPORT_TEMPLATE,
	GET_SAVED_CUSTOM_REPORT_TEMPLATES,
	UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE,
	GET_REPORT_COMPONENT_DATA,
	NEW_CUSTOM_REPORT,
	GET_CUSTOM_REPORT_DATA,
	GET_REPORT_COMPONENT_DATA_SUCCESS,
	GET_CUSTOM_REPORT_DATA_SUCCESS,
	CLEAR_RAW_DATA_BY_ID,
	COPY_SELECTED_REPORT_COMPONENT,
	SET_SELECTED_REPORT_COMPONENT,
	DELETE_SELECTED_REPORT_COMPONENT,
	SIGN_OUT,
	GET_GOOGLE_MARKETING_REPORT_DATA,
	GET_ECOMMERCE_REPORT_DATA,
	GET_FACEBOOK_MARKETING_REPORT_DATA,
	GET_LIST_OF_SELECTED_TEMPLATES,
	UPDATE_SELECTED_TEMPLATE_SUBSCRIBERS,
	DELETE_SELECTED_TEMPLATE,
	SAVE_SELECTED_TEMPLATE,
} from './types'
import { GetReportComponentDataResponse } from '../reducers/report'
import { Color } from 'material-ui-color'
import { GetProjectById } from './project'

interface Action<T, P> {
	readonly type: T
	readonly payload: { data: P }
}

interface ActionChangeField<T, P> {
	readonly type: T
	readonly payload: P
}

type ReportChangesFieldAction = ActionChangeField<
	typeof CHANGE_DRAFT_REPORT_TEMPLATE_FIELD,
	{ field: keyof ReportTemplatesType; value: string | IReportTemplateItem[] }
>

type ReportChangesPageFieldAction = ActionChangeField<
	typeof CHANGE_DRAFT_REPORT_PAGE_FIELD,
	{
		field: keyof ReportTemplatesType
		value: string | IReportPage[]
		index: number
	}
>
type SetSelectedCustomReportComponent = ActionChangeField<
	typeof SET_SELECTED_REPORT_COMPONENT,
	IReportComponent & { pageNumber: number }
>
type DeleteSelectedCustomReportComponent = ActionWithoutPayload<
	typeof DELETE_SELECTED_REPORT_COMPONENT
>
type CopySelectedCustomComponent = ActionWithoutPayload<
	typeof COPY_SELECTED_REPORT_COMPONENT
>

type ResetReportTemplate = ActionWithoutPayload<
	typeof CLEAR_DRAFT_PROJECT_TEMPLATE
>
type NewCustomReport = ActionWithoutPayload<typeof NEW_CUSTOM_REPORT>
type GetReportTemplates = Action<
	typeof GET_REPORT_TEMPLATES_SUCCESS,
	ReportTemplatesType[]
>
type GetDefaultReportTemplates = Action<
	typeof GET_REPORT_DEFAULT_TEMPLATES_SUCCESS,
	ReportTemplatesType[]
>
type SaveReportTemplate = Action<
	typeof POST_REPORT_TEMPLATE_SUCCESS,
	ReportTemplatesType
>
type ClearRawDataById = ActionChangeField<
	typeof CLEAR_RAW_DATA_BY_ID,
	{ id: string }
>
type UpdateReportTemplate = Action<
	typeof POST_UPDATE_REPORT_TEMPLATE_SUCCESS,
	ReportTemplatesType
>
type SelectReportTemplate = ActionChangeField<
	typeof SELECT_REPORT_TEMPLATE,
	ReportTemplatesType
>
type DeleteReportTemplate = Action<typeof DELETE_REPORT_TEMPLATE, string>
type GetReportComponentDta = Action<
	typeof GET_REPORT_COMPONENT_DATA_SUCCESS,
	GetReportComponentDataResponse
>
type GetCustomReportData = Action<
	typeof GET_CUSTOM_REPORT_DATA_SUCCESS,
	GetReportComponentDataResponse[]
>
type SignOut = ActionWithoutPayload<typeof SIGN_OUT>
export type ReportActions =
	| ReportChangesFieldAction
	| ReportChangesPageFieldAction
	| ResetReportTemplate
	| SaveReportTemplate
	| GetReportTemplates
	| GetDefaultReportTemplates
	| SelectReportTemplate
	| DeleteReportTemplate
	| UpdateReportTemplate
	| NewCustomReport
	| GetReportComponentDta
	| GetCustomReportData
	| ClearRawDataById
	| SetSelectedCustomReportComponent
	| CopySelectedCustomComponent
	| DeleteSelectedCustomReportComponent
	| SignOut
	| GetProjectById

export const saveReportTemplate = (
	projectId: number,
	companyId: number,
	template: ReportTemplatesType,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'companyId'],
		[projectId, companyId],
		API.POST_REPORT_TEMPLATE,
	)
	return {
		type: POST_REPORT_TEMPLATE,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: template,
			},
		},
	}
}

export const getDefaultTemplates = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_REPORT_DEFAULT_TEMPLATES,
	)
	return {
		type: GET_REPORT_DEFAULT_TEMPLATES,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getTemplatesByAccountId = (
	projectId: number,
	companyId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'companyId'],
		[projectId, companyId],
		API.GET_REPORT_TEMPLATES,
	)
	return {
		type: GET_REPORT_TEMPLATES,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const updateReportTemplate = (
	projectId: number,
	companyId: number,
	templateId: string,
	template: ReportTemplatesType,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'companyId', 'templateId'],
		[projectId, companyId, templateId],
		API.POST_UPDATE_REPORT_TEMPLATE,
	)
	return {
		type: POST_UPDATE_REPORT_TEMPLATE,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data: template,
			},
		},
	}
}
export const deleteTemplate = (
	projectId: number,
	companyId: number,
	templateId: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'companyId', 'templateId'],
		[projectId, companyId, templateId],
		API.DELETE_REPORT_TEMPLATE,
	)
	return {
		type: DELETE_REPORT_TEMPLATE_SUCCESS,
		payload: {
			request: {
				method: 'DELETE',
				url: apiUri,
			},
		},
	}
}
export const setTemplate = (template: ReportTemplatesType): any => {
	return {
		type: SELECT_REPORT_TEMPLATE,
		payload: template,
	}
}
export const newCustomReport = (): any => {
	return {
		type: NEW_CUSTOM_REPORT,
	}
}
export const copySelectedCustomComponent = (): any => {
	return {
		type: COPY_SELECTED_REPORT_COMPONENT,
	}
}
export const deleteSelectedCustomComponent = (): any => {
	return {
		type: DELETE_SELECTED_REPORT_COMPONENT,
	}
}
export const setSelectedCustomReportComponent = (
	component: IReportComponent | null,
	pageNumber: number,
): any => {
	return {
		type: SET_SELECTED_REPORT_COMPONENT,
		payload: { ...component, pageNumber },
	}
}
export const resetDraftReportTemplate = (): any => {
	return {
		type: CLEAR_DRAFT_PROJECT_TEMPLATE,
	}
}
export const clearRawReportDataRowById = (id: string): any => {
	return {
		type: CLEAR_RAW_DATA_BY_ID,
		payload: { id },
	}
}
export const changeDraftTemplateField = (payload: {
	field: keyof ReportTemplatesType | 'theme' | 'layout'
	value:
		| string
		| Color
		| ReportTemplatesType['items']
		| ReportTemplateCustom['theme']
		| ReportTemplateCustom['layout']
}): any => {
	return {
		type: CHANGE_DRAFT_REPORT_TEMPLATE_FIELD,
		payload,
	}
}
export const changeDraftReportPageField = (payload: {
	field: keyof IReportPage
	index: number
	value:
		| string
		| IReportComponent[]
		| IReportPage['color']
		| number
		| IReportPage['theme']
}): any => {
	return {
		type: CHANGE_DRAFT_REPORT_PAGE_FIELD,
		payload,
	}
}
export const getAutomateReportData = (token: string): any => {
	const apiUri = replaceParamsUri(
		['token'],
		[token],
		API.GET_AUTOMATE_REPORT_DATA,
	)
	return {
		type: GET_AUTOMATE_REPORT_DATA,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const getGeneratedMonthlyReport = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_GENERATED_MONTHLY_REPORT,
	)
	return {
		type: GET_GENERATED_MONTHLY_REPORT,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const getSavedReportFile = (
	projectId: number,
	reportId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'reportId'],
		[projectId, reportId],
		API.GET_SAVED_REPORT_FILE,
	)
	return {
		type: GET_SAVED_REPORT_FILE,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const saveCustomReportTemplate = (
	projectId: number,
	companyId: number,
	template: ReportTemplatesType,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'companyId'],
		[projectId, companyId],
		API.CREATE_CUSTOM_REPORT_TEMPLATE,
	)
	return {
		type: SAVE_CUSTOM_REPORT_TEMPLATE,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: template,
			},
		},
	}
}

export const getCustomTemplatesByCompanyId = (
	projectId: number,
	companyId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'companyId'],
		[projectId, companyId],
		API.GET_SAVED_CUSTOM_REPORT_TEMPLATES,
	)
	return {
		type: GET_SAVED_CUSTOM_REPORT_TEMPLATES,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const updateCustomReportTemplate = (
	projectId: number,
	companyId: number,
	templateId: string,
	template: Omit<ReportTemplatesType, 'type'>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'companyId', 'templateId'],
		[projectId, companyId, templateId],
		API.UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE,
	)
	return {
		type: UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data: template,
			},
		},
	}
}

export const getComponentData = (
	projectId: number,
	component: IReportComponent,
): any => {
	const apiUrl = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_REPORT_COMPONENT_DATA,
	)
	return {
		type: GET_REPORT_COMPONENT_DATA,
		payload: {
			request: {
				method: 'POST',
				url: apiUrl,
				data: component,
			},
		},
	}
}
export const getCustomReportData = (
	projectId: number,
	component: IReportPage,
): any => {
	const apiUrl = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_CUSTOM_REPORT_DATA,
	)
	return {
		type: GET_CUSTOM_REPORT_DATA,
		payload: {
			request: {
				method: 'POST',
				url: apiUrl,
				data: component,
			},
		},
	}
}

export const getListOfSelectedTemplates = (projectId: number): any => {
	const apiUrl = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_LIST_OF_SELECTED_TEMPLATES,
	)
	return {
		type: GET_LIST_OF_SELECTED_TEMPLATES,
		payload: {
			request: {
				method: 'GET',
				url: apiUrl,
			},
		},
	}
}

export const updateSelectedTemplateSubscribers = (
	projectId: number,
	selectedTemplateId: number,
	emailSubscribers: string[],
): any => {
	const apiUrl = replaceParamsUri(
		['projectId', 'selectedTemplateId'],
		[projectId, selectedTemplateId],
		API.UPDATE_SELECTED_TEMPLATE_SUBSCRIBERS,
	)
	return {
		type: UPDATE_SELECTED_TEMPLATE_SUBSCRIBERS,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUrl,
				data: { emailSubscribers: emailSubscribers },
			},
		},
	}
}

export const deleteSelectedTemplate = (
	projectId: number,
	selectedTemplateId: number,
): any => {
	const apiUrl = replaceParamsUri(
		['projectId', 'selectedTemplateId'],
		[projectId, selectedTemplateId],
		API.UPDATE_SELECTED_TEMPLATE_SUBSCRIBERS,
	)
	return {
		type: DELETE_SELECTED_TEMPLATE,
		payload: {
			request: {
				method: 'DELETE',
				url: apiUrl,
			},
		},
	}
}

export const saveSelectedTemplate = (
	projectId: number,
	selectedTemplateId: number,
	emailSubscribers?: string[],
): any => {
	const apiUrl = replaceParamsUri(
		['projectId', 'selectedTemplateId'],
		[projectId, selectedTemplateId],
		API.GET_LIST_OF_SELECTED_TEMPLATES,
	)
	return {
		type: SAVE_SELECTED_TEMPLATE,
		payload: {
			request: {
				method: 'POST',
				url: apiUrl,
				data: {
					templateId: selectedTemplateId,
					emailSubscribers: emailSubscribers || null,
				},
			},
		},
	}
}

export const getEcommerceReportData = (
	projectId: number,
	timeFrame: EMarketingReportTimeFrame,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'timeFrame'],
		[projectId, timeFrame],
		API.GET_ECOMMERCE_REPORT_DATA,
	)
	return {
		type: GET_ECOMMERCE_REPORT_DATA,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getGoogleMarketingReportData = (
	projectId: number,
	timeFrame: EMarketingReportTimeFrame,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'timeFrame'],
		[projectId, timeFrame],
		API.GET_GOOGLE_MARKETING_REPORT_DATA,
	)
	return {
		type: GET_GOOGLE_MARKETING_REPORT_DATA,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getFacebookMarketingReportData = (
	projectId: number,
	timeFrame: EMarketingReportTimeFrame,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'timeFrame'],
		[projectId, timeFrame],
		API.GET_FACEBOOK_MARKETING_REPORT_DATA,
	)
	return {
		type: GET_FACEBOOK_MARKETING_REPORT_DATA,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
