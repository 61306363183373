import React from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import { styled } from '@material-ui/core/styles'
import { DASHBOA_PRIMARY_COLOR } from '../../constants'

const StyledToolTip = styled(({ className, ...props }) => (
	<Tooltip placement='' {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: DASHBOA_PRIMARY_COLOR,
		boxShadow: '1px 1px 1px 1px #ffffff25',
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: DASHBOA_PRIMARY_COLOR,
	},
}))

const ToolTip = (props: {
	title: string
	delay?: number
	children: any
	placement?:
		| 'bottom'
		| 'left'
		| 'right'
		| 'top'
		| 'bottom-end'
		| 'bottom-start'
		| 'left-end'
		| 'left-start'
		| 'right-end'
		| 'right-start'
		| 'top-end'
		| 'top-start'
}): JSX.Element => {
	const { children, title, delay = 500, placement = 'bottom' } = props
	return (
		<StyledToolTip
			className='tool-tip'
			title={<p className='tool-tip__title'>{title}</p>}
			enterDelay={delay}
			arrow
			placement={placement}
			TransitionComponent={Zoom}
		>
			{children}
		</StyledToolTip>
	)
}

export default ToolTip
